import React from "react";
// import { Link } from "react-router-dom";
import './Button.scss'

export default ({ text, src, color }) => {
    
    return (
        <a href={src} className={`button button_${color}`} target="_blank" rel="noopener noreferrer">
                    <span className="hyphen"></span>
                    <span className="button_text">{text}</span>
        </a>
    );
};
